import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import styles from './index.module.scss';
import { SvgIcon } from '@ui-elements';
import { toCamelCase } from '~/common/utils';
import {
  Button,
  IconEnum,
  TooltipDisclaimer,
  FloatingDisclaimer,
} from '~/common/components/ui-elements';
import { SpecItem, ImageDetails } from './models';
import stylesHeroCard from '../hero-card/index.module.scss';
import { CallToAction } from '~/common/models';
import cn from 'classnames';

type PerformanceAndSpecsModel = {
  id: string;
  sectionHeader: string;
  heading: string;
  description: string;
  headingDisclaimer: string;
  disclaimer: string;
  imageDetails: ImageDetails;
  contentCTAs: CallToAction[];
  gridData: SpecItem[];
  imagePosition: string;
};

export const PerformanceAndSpecs = (props: PerformanceAndSpecsModel) => {
  return (
    <div id={`cid-${props.id}`}>
      <div
        className={cn(styles.ContentContainer, {
          [styles.Reversed]: props.imagePosition === 'ImageOnRight',
        })}
      >
        <div
          className={cn(styles.ContentImage, {
            [styles.Reversed]: props.imagePosition === 'ImageOnRight',
          })}
        >
          <img src={props.imageDetails.source} alt="" />
          <div
            className={styles.ContentImageDisclaimer}
            dangerouslySetInnerHTML={{ __html: props.imageDetails.disclaimer }}
          />
        </div>
        <div
          className={cn(styles.ContentSpecsWrapper, {
            [styles.Reversed]: props.imagePosition === 'ImageOnRight',
          })}
        >
          <div className={styles.ContentSpecsContainer}>
            {/* <span className={styles.ContentSpecsLabel}>Performance</span> */}
            <div dangerouslySetInnerHTML={{ __html: props.sectionHeader }} />
            <div className={styles.ContentSpecsHeading}>
              <div dangerouslySetInnerHTML={{ __html: props.heading }} />
              {!!props.headingDisclaimer && (
                <TooltipDisclaimer disclaimer={props.headingDisclaimer} />
              )}
            </div>
            <div
              className={styles.ContentSpecsDescription}
              dangerouslySetInnerHTML={{ __html: props.description }}
            />
            <div className={styles.ContentSpecsGrid}>
              {props.gridData.map((gridData, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className={styles.SpecTitle}>
                      {gridData.rowName}
                      {!!gridData.nameDisclaimer && (
                        <div className={styles.SpecValueDisclaimer}>
                          <TooltipDisclaimer
                            disclaimer={gridData.nameDisclaimer}
                          />
                        </div>
                      )}
                    </div>
                    <div className={styles.SpecValue}>
                      <div
                        dangerouslySetInnerHTML={{ __html: gridData.rowValue }}
                      />
                      {!!gridData.rowDisclaimer && (
                        <div className={styles.SpecValueDisclaimer}>
                          <FloatingDisclaimer
                            disclaimer={gridData.rowDisclaimer}
                          >
                            {({ renderTrigger }) => <>{renderTrigger()}</>}
                          </FloatingDisclaimer>
                        </div>
                      )}
                    </div>
                    <div className={styles.SpecBorder} />
                  </React.Fragment>
                );
              })}
            </div>
            <div className={styles.CTAContainer}>
              {props.contentCTAs?.map((cta, idx) => {
                const buttonProps: any = {
                  type: toCamelCase(cta.ctaButtonType),
                  href: cta.ctaUrl?.url,
                  target: cta.ctaUrl?.target ?? '_self',
                  onClick: () => {},
                };
                return (
                  <Button
                    key={idx}
                    className={cn(
                      stylesHeroCard.HeroCardCTA,
                      stylesHeroCard[`HeroCardCTA${buttonProps.type}`]
                    )}
                    {...buttonProps}
                  >
                    {cta.ctaIcon && cta.ctaIcon !== 'none' && (
                      <SvgIcon
                        className={styles.HeroCardCTAIcon}
                        type={cta.ctaIcon as keyof typeof IconEnum}
                        color="#1637a0"
                        size={1.2}
                        strokeWidth={2}
                      />
                    )}
                    <span
                      style={
                        buttonProps.type === 'text' ? { color: '#1637a0' } : {}
                      }
                    >
                      {cta.ctaUrl?.name || ''}
                    </span>
                  </Button>
                );
              })}
            </div>
            <div
              className={styles.ContentDisclaimer}
              dangerouslySetInnerHTML={{ __html: props.disclaimer }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
